#root > div > div > main > div > div > h6 > div > nav > ol {
    width: max-content;
}

#root > div > div > main > div > div > nav > ol {
    width: max-content;
}

.title {
    color: #6D6D6D;;
    line-height: 1.5714285714285714;
    font-size: 1rem;
    font-family: Nunito Sans, sans-serif;
    font-weight: 400;
}