.register-form {
    overflow: hidden;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    /* Add a shadow */
    border-radius: 10px;
    /* Add border radius */
    padding: 20px;
    /* Add padding if needed */
    /* max-width: 480px; */
    /* Set the max width if needed */
    margin: auto;
    /* Center the form */
    background: white;

    width: 100%;

}
  /* .register-form {
        width: 100%;

    } */

